import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  CircularProgress,  // Import CircularProgress for loading spinner
} from "@mui/material";
import { toast } from 'react-hot-toast';
import {
  FaEye,
  FaPlay,
  FaTrashAlt,
  FaSpinner,
  FaCheckCircle,
} from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { confirm } from '../../../../components/Confirmable';
import ChatFloatingIcon from '../../../../components/Dialog/ChatWindow';
import API from '../../../../Network/aishaala';
import { getContentType } from "../../../../utils/getContentType";
import AddFile from "./AddFile";

const NOTE_TYPES = {
  ALL: "all",
  FILE: "file",
  WEB_PAGE: "web_page",
  YOUTUBE_LINK: "youtube_link",
  TEXT: "text"
};

const FILTERS = [
  { label: 'All', value: NOTE_TYPES.ALL },
  { label: 'File', value: NOTE_TYPES.FILE },
  { label: 'Web Page', value: NOTE_TYPES.WEB_PAGE },
  { label: 'Youtube Link', value: NOTE_TYPES.YOUTUBE_LINK },
  { label: 'Text', value: NOTE_TYPES.TEXT },
];

const getNoteTypeLabel = (type) => {
  switch (type) {
    case NOTE_TYPES.FILE:
      return "File";
    case NOTE_TYPES.WEB_PAGE:
      return "Web Page";
    case NOTE_TYPES.YOUTUBE_LINK:
      return "Youtube Link";
    case NOTE_TYPES.TEXT:
      return "Text";
    default:
      return "Other";
  }
};

const FileTable = () => {
  const { folderId } = useParams();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileTypeFilter, setFileTypeFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setModalOpen] = useState(null); // Initialize as null
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false); // disable form fields while submitting
  const [currentBuild, setCurrentBuild] = useState(null);

  useEffect(() => {
    fetchNotes();
  }, [folderId]);

  const fetchNotes = async () => {
    setIsLoading(true);
    try {
      const response = await API.getInstance().getAllKnowledge(folderId);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast.error("Failed to fetch folders.");
    } finally {
      setIsLoading(false);
    }
  };

  // Filtered data logic
  const filteredData = data.filter(
    (row) =>
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (fileTypeFilter === "all" || row.type === fileTypeFilter)
  );

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFileTypeFilter(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createNotes = async (data) => {
    const formData = new FormData();
    const dataCopy = {
      ...data,
      content_type: 'file',
      folder_id: folderId,
    };
    const file_content = dataCopy.file_content;
    delete dataCopy.file_content;
    formData.append('form_data', JSON.stringify(dataCopy));
    formData.append('file_content', file_content);

    try {
      setDisable(true);
      await API.getInstance().createKnowledge(formData);
      toast.success("File uploaded successfully!");
      setModalOpen(null);
      await fetchNotes();
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setDisable(false);
    }
  };

  const buildNotes = async (data) => {
    if (await confirm({ confirmation: 'Are you sure you want to build notes?', iconType: 'warning' })) {
      try {
        setCurrentBuild({
          data,
          status: 'in_progress',
        });
        await API.getInstance().buildKnowledge(data?.id);
        toast.success("Notes built successfully");
        setCurrentBuild({
          data,
          status: 'success',
        });
      } catch (error) {
        console.error("Build error:", error);
        toast.error("An error occurred while building notes");
      }
    }
  }

  const deleteNotes = async (data) => {
    if (await confirm({ confirmation: 'Are you sure you want to delete notes?', iconType: 'danger' })) {
      try {
        await API.getInstance().deleteKnowledge(data?.id);
        toast.success("Notes deleted successfully");
        fetchNotes();
      } catch (error) {
        console.error("Delete error:", error);
        toast.error("An error occurred while deleting notes");
      }
    }
  }

  const handleModalClose = () => setModalOpen(null);

  const renderModalContent = () => {
    if (!isModalOpen) return null;

    switch (isModalOpen.type) {
      case "view":
        return (
          <DocViewer
            documents={[{ uri: isModalOpen.data, fileType: getContentType(isModalOpen.data) }]}
            pluginRenderers={DocViewerRenderers}
          />
        );
      case "add":
        return (
          <AddFile
            createNotes={createNotes}
            disable={disable}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className="p-3 h-full">
      <Box className="mb-3 flex gap-3 justify-between">
        <Box className="flex gap-3">
          {/* Filter */}
          <FormControl size="small" className="w-56">
            <InputLabel>Filter by Type</InputLabel>
            <Select
              value={fileTypeFilter}
              onChange={handleFilterChange}
              label="Filter by Type"
            >
              {FILTERS.map((filter) => (
                <MenuItem key={filter.value} value={filter.value}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Search */}
          <TextField
            label="Search by Name"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
          />
        </Box>

        {/* Knowledge Button */}
        <Button variant="contained" color="primary" onClick={() => setModalOpen({ type: "add" })}>
          Add Knowledge
        </Button>
      </Box>

      {/* Show loader while data is loading */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer className="overflow-y-auto" style={{ maxHeight: "calc(100vh - 13rem)" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-[3%] sticky top-0 bg-white">S.No</TableCell>
                <TableCell className="w-[8%] sticky top-0 bg-white">Type</TableCell>
                <TableCell className="w-[15%] sticky top-0 bg-white">Category</TableCell>
                <TableCell className="w-[35%] sticky top-0 bg-white">Name</TableCell>
                <TableCell className="w-[20%] sticky top-0 bg-white">Uploaded Date</TableCell>
                <TableCell className="w-[10%] sticky top-0 bg-white">Filesize</TableCell>
                <TableCell className="w-[3%] sticky top-0 bg-white">View</TableCell>
                <TableCell className="w-[3%] sticky top-0 bg-white">Build</TableCell>
                <TableCell className="w-[3%] sticky top-0 bg-white">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{getNoteTypeLabel(row.content_type)}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>
                    <Tooltip title={row.name} placement="top">
                      <span className="truncate block max-w-[150px]">{row.name}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {new Date(row.uploaded_date)
                      .toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                  </TableCell>
                  <TableCell>{row.size}</TableCell>
                  <TableCell>
                    <IconButton
                      variant="outlined"
                      size="small"
                      className="p-2"
                      onClick={() => setModalOpen({ type: "view", data: row.content_source })}
                    >
                      <FaEye size={16} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={row.process_status} placement="top">
                      <IconButton
                        variant="outlined"
                        size="small"
                        color="success"
                        className="p-2"
                        disabled={row.process_status === "PROCESSING" || row.process_status === "PROCESSED"}
                        onClick={() => buildNotes(row)}
                      >
                        {(row.process_status === "PENDING"
                          && (row.id !== currentBuild?.data?.id))
                          && <FaPlay size={16} />}
                        {(row.process_status === "PROCESSING"
                          || (row.id === currentBuild?.data?.id && currentBuild?.status === 'in_progress'))
                          && <FaSpinner size={16} />}
                        {(row.process_status === "PROCESSED"
                          || (row.id === currentBuild?.data?.id && currentBuild?.status === 'success'))
                          && <FaCheckCircle size={16} />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      variant="outlined"
                      size="small"
                      color="error"
                      className="p-2"
                      onClick={() => deleteNotes(row)}
                    >
                      <FaTrashAlt size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal */}
      <Modal
        open={!!isModalOpen}
        onClose={handleModalClose}
        className="flex items-center"
      >
        <Box
          className="bg-[#FAFAFA] mx-auto w-[400px] rounded-lg shadow-lg relative py-4 max-h-[90vh] overflow-y-auto"
        >
          {/* Close Icon */}
          <IconButton
            onClick={handleModalClose}
            className="absolute top-2 right-2"
            size="small"
            aria-label="close"
            disabled={disable}
          >
            <IoMdCloseCircleOutline size={18} />
          </IconButton>

          {/* Modal Content */}
          {renderModalContent()}
        </Box>
      </Modal>
      <ChatFloatingIcon />
    </Box>
  );
};

export default FileTable;
