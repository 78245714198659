import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  TextField,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import API from "../../Network/aishaala";

const FoldersNavBar = (props) => {
  const [folders, setFolders] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { folderId } = useParams();
  const navigate = useNavigate(); // Hook to navigate programmatically

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    setIsLoading(true);
    try {
      const response = await API.getInstance().getAllFolder();
      setFolders(response.data);
      setFilteredFolders(response.data);

      // If no folderId is present in the URL and folders exist, set the first folder's ID as the parameter
      if (!folderId && response.data.length > 0) {
        navigate(`/knowledge/notes/${response.data[0].id}`);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast.error("Failed to fetch folders.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredFolders(folders);
    } else {
      setFilteredFolders(
        folders.filter((folder) =>
          folder.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, folders]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddFolder = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setNewFolderName("");
    setError("");
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      toast.error("Folder name is required.");
      return;
    }

    try {
      await API.getInstance().createFolder({
        name: newFolderName.trim(),
      });

      await fetchFolders();
      handleClosePopup();
    } catch (error) {
      toast.error("Error creating folder.");
      setError("Failed to create folder. Please try again.");
    }
  };

  return (
    <div className="w-56 bg-gray-100 h-screen flex flex-col p-4 border-r">
      <h1 className="text-2xl font-bold mb-4 py-5 text-center">AIshaala</h1>

      {/* Add Folder Button */}
      <Button
        variant="contained"
        className="rounded-md"
        onClick={handleAddFolder}
        size="small"
      >
        Add Folder
      </Button>

      {/* Separator */}
      <hr className="my-3 border-t border-gray-300" />

      {/* Search Bar */}
      <TextField
        variant="outlined"
        placeholder="Search folders"
        value={searchTerm}
        onChange={handleSearchChange}
        size="small"
        className="mb-4"
        InputProps={{
          className: "bg-white",
        }}
      />

      {/* Folder List */}
      <div className="flex-1 overflow-y-auto">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress color="inherit" />
          </div>
        ) : filteredFolders.length === 0 ? (
          <div className="text-gray-500 text-center mt-4">
            No folders yet. Click "Add Folder" to create one.
          </div>
        ) : (
          filteredFolders.map((folder) => (
            <Tooltip title={folder.name} placement="right" key={folder.id}>
              <Link
                to={`/knowledge/notes/${folder.id}`}
                className={`flex items-center px-3 py-2 rounded-md mb-2 transition-colors duration-200 ${
                  folderId === folder.id
                    ? "bg-[#FCE4E4] text-[#F97171] font-semibold" // Active folder styles
                    : "bg-gray-50 text-gray-700 hover:bg-[#FDECEC] hover:text-[#F97171]" // Inactive folder styles
                }`}
              >
                {folderId === folder.id ? (
                  <FaFolderOpen className="mr-2 text-[#F97171]" size={18} />
                ) : (
                  <FaFolder className="mr-2 text-gray-400" size={18} />
                )}
                <span className="truncate">{folder.name}</span>
              </Link>
            </Tooltip>
          ))
        )}
      </div>

      {/* Add Folder Popup */}
      <Dialog open={isPopupOpen} onClose={handleClosePopup}>
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Add New Folder</h2>
          <TextField
            label="Folder Name"
            variant="outlined"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            error={!!error}
            helperText={error}
            className="mb-4"
            size="small"
          />
          <div className="flex justify-end">
            <Button
              onClick={handleClosePopup}
              className="mr-2"
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="text-white"
              onClick={handleCreateFolder}
              size="small"
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FoldersNavBar;
