import {
  amber, grey, green, red, common,
} from '@mui/material/colors';

const colors = {
  white: common.white,
  background: grey[50],
  primary: '#F97171',
  secondary: '#222222',
  positive: green[500],
  medium: amber[700],
  negative: red[500],
  neutral: '#798897',
};

const palette = {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
    contrastText: 'white',
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
};

export default palette;
