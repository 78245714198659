import React from 'react';
import { useParams } from 'react-router-dom';
import FoldersNavBar from '../../../../components/FoldersNavBar';
import NotesTable from './NotesTable';

const Notes = (props) => {
  const { folderId } = useParams();

  return (
    <div className="flex">
      <FoldersNavBar />
      <div className="pt-[4rem] flex-1 h-screen">
        {folderId ? (
          <NotesTable folderId={folderId} />
        ) : (
          <div>
            <div
              className="flex flex-col justify-center items-center"
              style={{ height: "calc(100vh - 4rem)" }}
            >
              <h1 className="text-3xl font-bold text-primary">
                Oops! No folder selected.
              </h1>
              <p className="mt-2 text-lg text-primary">
                Please select a folder to view the notes.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default Notes;
