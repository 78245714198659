import React from "react";
import { TextField } from "@mui/material";
import ElementUtils from '../utils';

function Input(props) {
  const {
    label,
    placeholder,
    type = "text",
    value,
    onChange,
    error,
    helperText,
    disabled,
    className = "",
    inputClassName = "",
    labelClassName = "",
    helperTextClassName = "",
    errorTextClassName = "",
  } = props;

  const handleChange = (event) => {
    if (!disabled && onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={`flex flex-col gap-2 w-full ${className}`}>
      {label && (
        <label
          className={`text-sm font-medium ${
            error ? "text-red-500" : "text-gray-700"
          } ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <TextField
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        variant="outlined"
        disabled={disabled}
        fullWidth
        error={!!error} // Triggers MUI error styles
        InputProps={{
          classes: {
            input: `text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 bg-white border ${
              error ? "border-red-500" : "border-gray-300"
            } rounded-md px-3 py-2 ${
              disabled ? "bg-gray-100 cursor-not-allowed" : ""
            } ${inputClassName}`,
          },
        }}
        className="w-full"
      />
      {/* Helper Text */}
      {helperText && (
        <span
          className={`text-xs text-gray-500 mt-1 ${helperTextClassName}`}
        >
          {helperText}
        </span>
      )}
      {/* Error Text */}
      {error && (
        <span
          className={`text-xs text-red-500 mt-1 ${errorTextClassName}`}
        >
          {ElementUtils.renderError(error)}
        </span>
      )}
    </div>
  );
}

Input.defaultProps = {
  label: "",
  placeholder: "",
  type: "text",
  value: "",
  onChange: () => {},
  error: "",
  helperText: "",
  disabled: false,
  className: "",
  inputClassName: "",
  labelClassName: "",
  helperTextClassName: "",
  errorTextClassName: "",
};

export default Input;
