import React from "react";
import { ThemeProvider } from '@emotion/react';
import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  StyledEngineProvider,
} from '@mui/material';
import { twMerge } from 'tailwind-merge';
import {
  MdDangerous as DangerIcon,
  MdCheckCircle as SuccessIcon,
  MdWarning as WarningIcon
} from 'react-icons/md';
import {
  AiOutlineCloseCircle as CloseIcon, 
} from 'react-icons/ai';
import theme from '../../theme';

const Confirm = ({
  show,
  proceed,
  confirmation = "Are you sure?",
  okLabel = "OK",
  cancelLabel = "Cancel",
  iconType = "warning",
  fullWidth = false,
  hidePrimaryBtn = false,
  hideSecondaryBtn = false,
  isLoading = false,
  maxWidth = 'md',
  containerClasses,
  showCloseButton = false,
  hideIcon = false,
}) => {
  if (!show) return null;

  const getIcon = () => {
    if (hideIcon) {
      return null;
    }
    switch (iconType) {
      case 'danger':
        return <DangerIcon className="text-red-600 text-6xl mx-auto" />;
      case 'warning':
        return <WarningIcon className="text-yellow-600 text-6xl mx-auto" />;
      case 'success':
        return <SuccessIcon className="text-green-600 text-6xl mx-auto" />;
      default:
        return null;
    }
  };

  return (

    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Dialog
          classes={{ paper: 'shadow-none' }}
          open={show}
          fullWidth={fullWidth}
          onClose={() => proceed(false)}
          aria-describedby="alert-dialog-slide-description"
          transitionDuration={{
            enter: 500,
            exit: 500,
          }}
          maxWidth={maxWidth}
        >
          {
            showCloseButton && (
              <div className="sticky flex justify-end mr-2 right-2 top-2 z-10" onClick={() => proceed(false)}>
                <CloseIcon className="cursor-pointer text-gray-500 text-2xl" />
              </div>
            )
          }
          <div className={twMerge('p-10', containerClasses)}>
            {getIcon()}
            {confirmation && <DialogTitle sx={{ textAlign: 'center', paddingTop: '10px' }}>{confirmation}</DialogTitle>}
            {(!hideSecondaryBtn || !hidePrimaryBtn) && (
              <DialogActions className="pt-3">
                {!hideSecondaryBtn
                  && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      disabled={isLoading}
                      onClick={() => proceed(false)}
                    >
                      {cancelLabel}
                    </Button>
                  )}
                {!hidePrimaryBtn
                  && (
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      disabled={isLoading}
                      onClick={() => proceed(true)}
                    >
                      {okLabel}
                    </Button>
                  )}
              </DialogActions>
            )}
          </div>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Confirm;
