import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useDropzone } from "react-dropzone";

import RHFInput from '../../components/elements/Input/RHFInput';
import RHFTextArea from '../../components/elements/Textarea/RhTextArea';
import useYupValidationResolver from '../../hooks/useYupValidationResolver';
import { confirm } from '../../components/Confirmable';
import API from '../../Network/aishaala';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  legal_name: yup.string().nullable().required('Legal Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  admin_email: yup.string().email('Invalid email'),
  phone: yup
    .string()
    .matches(/^\d{10,13}$/, 'Phone number must be between 10 and 13 digits')
    .required('Phone number is required'),
  website: yup.string().url('Invalid URL').required('Website is required'),
  address: yup.string().required('Address is required'),
  pin_code: yup
    .string()
    .matches(/^\d{6}$/, 'PIN Code must be 6 digits')
    .required('PIN Code is required'),
  logo: yup.mixed()
    .required('Logo is required')
    .test("fileType", "Unsupported file format", (value) => {
      return value && [
        "image/jpeg",
        "image/png"
      ].includes(value.type);
    }),
});

const FileDropzone = ({ name, control, label, rules, disable }) => {
  const onDrop = useCallback((acceptedFiles, onChange) => {
    if (acceptedFiles.length) {
      onChange(acceptedFiles[0]);
    }
  }, []);

  const DropzoneContent = ({ value, onChange, error }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, onChange),
      multiple: false,
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
      disabled: disable || !!value,
    });

    const handleRemoveFile = () => {
      onChange(null);
    };

    return (
      <div className="mb-4">
        <label className="block font-medium text-sm text-gray-700">{label}</label>
        {value ? (
          <div className="flex items-center justify-between border border-gray-400 p-4 rounded-md bg-gray-50">
            <p className="text-gray-600">{value.name}</p>
            <button
              type="button"
              onClick={handleRemoveFile}
              className="text-red-500 hover:text-red-700 text-sm font-medium"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={`border-dashed border-2 p-4 rounded-md bg-gray-50 text-center cursor-pointer ${error ? "border-red-500" : "border-gray-400"
              }`}
          >
            <input {...getInputProps()} />
            <p className="text-gray-600">
              Drag and drop a file here, or click to select one
            </p>
          </div>
        )}
        {error && <p className="text-red-600 text-sm mt-2">{error.message}</p>}
      </div>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DropzoneContent value={value} onChange={onChange} error={error} />
      )}
    />
  );
};

function Register({ onSubmit, defaultValues, disable }) {
  const { data } = useSelector((state) => state.user);
  const { institute } = data || {};
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    defaultValues: defaultValues || {
      name: '',
      legal_name: '',
      email: '',
      phone: '',
      website: '',
      address: '',
      pin_code: '',
      logo: null,
      admin_email: '',
    },
    resolver,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const submitHandler = async (data) => {
    if (await confirm({ confirmation: 'Are your sure you want to register?', iconType: 'warning' })) {
      try {
        setIsSubmitting(true);
        const formData = new FormData();
        const dataCopy = { ...data };
        const logo = dataCopy.logo;
        delete dataCopy.logo;
        formData.append('form_data', JSON.stringify(dataCopy));
        formData.append('logo', logo);
        if (onSubmit) {
          await onSubmit(data);
        }
        await API.getInstance().registerInstitute(formData);
        setIsSubmitted(true);
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (institute !== null && institute.is_approved) {
      navigate('/');
    }
  }, [institute, navigate]);

  if (institute && !institute.is_approved || isSubmitted) {
    // Show pending approval message
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h2 className="text-xl font-bold">We have recorded your response.</h2>
        <p className="text-gray-600">
          We will approve your request shortly.
        </p>
      </div>
    );
  }

  if (!institute) {
    // Render registration form
    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex justify-center items-center w-full h-[60px] py-10">
            <div className="flex justify-center text-[#28364C] font-medium text-base">
              Register Institution
            </div>
          </div>
          <div className="px-[10%] pt-10 bg-[#FAFAFA] grid grid-cols-1 sm:grid-cols-2 gap-5">
            <RHFInput
              name="name"
              control={methods.control}
              label="Institute Name"
              disabled={disable || isSubmitting}
            />
            <RHFInput
              name="legal_name"
              control={methods.control}
              label="Legal Name"
              disabled={disable || isSubmitting}
            />
            <RHFInput
              name="email"
              control={methods.control}
              label="Institute Email"
              disabled={disable || isSubmitting}
            />
            <RHFInput
              name="admin_email"
              control={methods.control}
              label="Admin Email"
              disabled={disable || isSubmitting}
            />
          </div>
          <div className="px-[10%] pt-6 bg-[#FAFAFA] grid grid-cols-1 sm:grid-cols-3 gap-5">
            <RHFInput
              name="phone"
              control={methods.control}
              label="Phone Number"
              disabled={disable || isSubmitting}
            />
            <RHFInput
              name="website"
              control={methods.control}
              label="Website"
              disabled={disable || isSubmitting}
            />
            <RHFInput
              name="pin_code"
              control={methods.control}
              label="PIN Code"
              disabled={disable || isSubmitting}
            />
          </div>
          <div className="px-[10%] pt-6 bg-[#FAFAFA] grid grid-cols-1 gap-5">
            <RHFTextArea
              name="address"
              control={methods.control}
              label="Address"
              disabled={disable || isSubmitting}
            />
            <FileDropzone
              name="logo"
              control={methods.control}
              label="Upload Logo"
              disable={disable || isSubmitting}
            />
          </div>
          <div className="flex justify-center items-center py-[30px] gap-4">
            <Button
              variant="outlined"
              size="large"
              disabled={!isDirty || disable || isSubmitting}
              onClick={() => reset(defaultValues)}
            >
              Reset
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              disabled={disable || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: 8 }} />
                  Registering...
                </>
              ) : (
                'Register'
              )}
            </Button>
          </div>
        </form>
      </FormProvider>
    );
  }

  return null;
}

Register.defaultProps = {
  defaultValues: {
    name: '',
    legal_name: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    pin_code: '',
    logo: null,
  },
};

export default Register;
