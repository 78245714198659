import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';

import API from './Network/aishaala';
import { setUserinfo } from './redux/slices/userSlice';
import LandingPage from './pages/Home';
import Verify from './pages/Verify';
import Knowledge from './pages/Knowlege';
import Notes from './pages/Knowlege/components/Notes/Notes';
import Register from './pages/Registration/Register';
import FineTune from './pages/FineTune';
import Index from './pages/FineTune/components/Index/Index';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticated } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (authenticated) {
        const { data } = await API.getInstance().userinfo();
        dispatch(setUserinfo(data));
        if ((!data.institute || (data.institute && !data.institute.is_approved))
          && data.permissions.length === 0) {
          navigate('/register');
        } else if (data.institute && data.institute.is_approved) {
          navigate('/knowledge')
        }
      }
    };
    fetchUserInfo();
  }, [authenticated, dispatch]);

  return (
    <div>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/google' element={<Verify />} />
        <Route path='/register' element={<Register />} />

        <Route element={<ProtectedRoute />}>
          <Route path='/knowledge' element={<Knowledge />}>
            <Route path='notes/:folderId?' element={<Notes />} />
            <Route path='test/:folderId?' element={<>Test</>} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path='/finetune' element={<FineTune />}>
            <Route path='finetune' element={<>finetune</>} />
            <Route path='index' element={<Index />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
