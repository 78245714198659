import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ redirectPath = '/' }) => {
  const { authenticated } = useSelector((state) => state.user);

  // If the user is not authenticated, redirect to the specified path
  return authenticated ? <Outlet /> : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
