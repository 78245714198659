import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { login } from '../../redux/slices/userSlice';
import Auth from '../../Network/auth';

const Verify = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleLogin = async () => {
      try {
        // Extract the "code" from the URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
          // Call the async function Auth.googleLoginDone
          const response = await Auth.getInstance().googleLoginDone(code);
          // Dispatch the response to the Redux store
          dispatch(login({
            authenticated: true,
          }));
          localStorage.setItem('app_token', response.token);
        }
      } catch (err) {
        toast.error('Verification Failed');
      } finally {
        navigate('/');
      }
    };

    handleGoogleLogin();
  }, [dispatch]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-white text-xl flex">
        Verifying
        <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24" />
      </div>
    </div>
  )
}

export default Verify;
