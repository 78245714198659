import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Paper,
  Stack,
  Fab,
} from "@mui/material";
import { BsChatRightTextFill } from "react-icons/bs";
import { RiCloseLargeLine } from "react-icons/ri";
import { IoSendSharp } from "react-icons/io5";

import API from '../../Network/aishaala';

const ChatFloatingIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ws = useRef(null);

  useEffect(() => {
    return () => {
      console.log("Cleaning up WebSocket");
      ws.current?.close();
      ws.current = null;
    };
  }, []);

  return (
    <>
      <Fab
        color="primary"
        aria-label="chat"
        onClick={() => setIsOpen(true)}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <BsChatRightTextFill />
      </Fab>

      {isOpen && (<ChatWindow ws={ws} setIsOpen={setIsOpen} />)}
    </>
  )
}

const ChatWindow = (props) => {
  const { ws, setIsOpen } = props;
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const websocket = useRef(null);
  const pageLoaded = useRef(false);
  const messagesContainerRef = useRef(null); // Ref for the container
  const messageRefs = useRef([]);

  useEffect(() => {
    // Prevent multiple connections
    websocket.current = ws.current;

    if (websocket.current) return;

    const getChatToken = async () => {
      const { token } = await API.getInstance().getChatSessionId();
      return token;
    };

    const initializeWebSocket = async () => {
      if (pageLoaded.current) return;

      pageLoaded.current = true;
      const token = await getChatToken();
      // const API_ENDPOINT = process.env.REACT_APP_ROOT_DOMAIN;
      const API_ENDPOINT = 'https://app.aishaala.com/api/v1/';

      const protocol = API_ENDPOINT.split('//')[0] === 'https:' ? 'wss:' : 'ws:';
      websocket.current = new WebSocket(`${protocol}//${API_ENDPOINT.split('//')[1]}knowledge/chat/${token}`);

      ws.current = websocket.current;

      websocket.current.onopen = () => {
        console.log("WebSocket connected");
      };

      websocket.current.onmessage = (event) => {
        const receivedMessage = JSON.parse(event.data);
        // Remove "Generating..." message and add the received message
        setMessages((prevMessages) => [
          ...prevMessages.filter((msg) => msg.text !== "Generating..."), // Remove the placeholder
          {
            id: prevMessages.length + 1,
            text: receivedMessage.response_text,
            timestamp: new Date(Date.now()).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
            user: {
              name: receivedMessage?.user?.name || "Bot",
              avatar: receivedMessage?.user?.avatar || "B",
            },
            isMine: false,
          },
        ]);
      };

      websocket.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      websocket.current.onclose = () => {
        console.log("WebSocket disconnected");
      };
    };

    initializeWebSocket();
  }, []);


  useEffect(() => {
    // Scroll to the top of the last message with 10px offset
    if (messageRefs.current.length > 0) {
      const lastMessageRef = messageRefs.current[messageRefs.current.length - 1];
      if (lastMessageRef && messagesContainerRef.current) {
        const container = messagesContainerRef.current;
        const lastMessageOffset = lastMessageRef.offsetTop - 30;
        container.scrollTop = lastMessageOffset >= 0 ? lastMessageOffset : 0;
      }
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const messageData = {
      text: newMessage,
      timestamp: new Date().toISOString(),
      user: { name: "Me", avatar: "M" },
    };

    // Send message via WebSocket
    websocket.current?.send(JSON.stringify(messageData));

    // Add the sent message and "Generating..." placeholder to the local state
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        text: messageData.text,
        timestamp: new Date(messageData.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        user: messageData.user,
        isMine: true,
      },
      {
        id: prevMessages.length + 2, // Temporary ID for "Generating..."
        text: "Generating...",
        timestamp: "",
        user: { name: "Bot", avatar: "B" },
        isMine: false,
      },
    ]);

    setNewMessage("");
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          width: "400px",
          height: "95vh",
          display: "flex",
          flexDirection: "column",
          zIndex: 1300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "primary.main",
            color: "white",
            p: 1,
          }}
        >
          <Typography variant="h6">Chat</Typography>
          <IconButton
            color="inherit"
            onClick={() => setIsOpen(false)}
          >
            <RiCloseLargeLine />
          </IconButton>
        </Box>
        <Box
          ref={messagesContainerRef}
          sx={{ flex: 1, overflowY: "auto", p: 2 }}
        >
          {messages.map((msg, index) => (
            <Stack
              key={msg.id}
              direction={msg.isMine ? "row-reverse" : "row"}
              spacing={1}
              alignItems="flex-end"
              sx={{ mb: 2 }}
              ref={(el) => (messageRefs.current[index] = el)} // Attach ref to each message
            >
              <Avatar src={msg.user.avatar} alt={msg.user.name} />
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 0.5 }}
                >
                  {msg.isMine ? "You" : msg.user.name}
                  {msg.timestamp && ` • ${msg.timestamp}`}
                </Typography>
                <Box
                  sx={{
                    bgcolor: msg.isMine ? "primary.main" : "grey.300",
                    color: msg.isMine ? "white" : "black",
                    px: 2,
                    py: 1,
                    borderRadius: "16px",
                    maxWidth: "90%",
                  }}
                >
                  {msg.text}
                </Box>
              </Box>
            </Stack>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #ccc",
            p: 1,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Type a message..."
            size="small"
            fullWidth
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
            sx={{ mr: 1 }}
          />
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            disabled={newMessage.trim() === ""}
          >
            <IoSendSharp />
          </IconButton>
        </Box>
      </Paper>
    </>
  );
};

export default ChatFloatingIcon;
