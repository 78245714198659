export default class ElementUtils {
  /**
   * Type guard function to check if an error object is of type FieldError.
   * @param error The error object to check.
   * @returns True if the error is of type FieldError, false otherwise.
   */
  static isFieldError(error) {
    return (
      typeof error === 'object'
      && 'type' in error
      && typeof error.type === 'string'
    );
  }

  /**
   * Renders the appropriate error message based on the error object.
   * @param error The error object to render.
   * @returns The error message to display.
   */
  static renderError(error) {
    if (typeof error === 'string') {
      return error;
    }

    if (ElementUtils.isFieldError(error)) {
      return error.message;
    }

    return error.message;
  }
}
