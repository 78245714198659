import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  CircularProgress,  // Import CircularProgress for loading spinner
} from "@mui/material";
import { toast } from 'react-hot-toast';
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";

import API from '../../../../Network/aishaala';
import { getContentType } from "../../../../utils/getContentType";
import AddIndex from "./AddIndex";

const Index = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setModalOpen] = useState(null); // Initialize as null
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false); // disable form fields while submitting

  useEffect(() => {
    fetchIndex();
  }, []);

  const fetchIndex = async () => {
    setIsLoading(true);
    try {
      const response = await API.getInstance().getAllIndex();
      setData(response);
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast.error("Failed to fetch folders.");
    } finally {
      setIsLoading(false);
    }
  };

  // Filtered data logic
  const filteredData = data.filter((row) => row.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createIndex = async (data) => {
    const formData = new FormData();
    const dataCopy = { ...data };
    const file_content = dataCopy.file_content;
    delete dataCopy.file_content;
    formData.append('form_data', JSON.stringify(dataCopy));
    formData.append('file_content', file_content);

    try {
      setDisable(true);
      await API.getInstance().createAndParseIndex(formData);
      toast.success("Index created and parsed successfully");
      setModalOpen(null);
      await fetchIndex();
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setDisable(false);
    }
  };

  const handleModalClose = () => setModalOpen(null);

  const renderModalContent = () => {
    if (!isModalOpen) return null;

    switch (isModalOpen.type) {
      case "view":
        return (
          <DocViewer
            documents={[{ uri: isModalOpen.data, fileType: getContentType(isModalOpen.data) }]}
            pluginRenderers={DocViewerRenderers}
          />
        );
      case "add":
        return (
          <AddIndex
            createIndex={createIndex}
            disable={disable}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className="p-3">
      <Box className="mb-3 flex gap-3 justify-between">
        <Box className="flex gap-3">
          {/* Search */}
          <TextField
            label="Search by Name"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
          />
        </Box>

        {/* Knowledge Button */}
        <Button variant="contained" color="primary" onClick={() => setModalOpen({ type: "add" })}>
          Add Index
        </Button>
      </Box>

      {/* Show loader while data is loading */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer className="overflow-y-auto" style={{ maxHeight: "calc(100vh - 13rem)" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-[5%] sticky top-0 bg-white">S.No</TableCell>
                <TableCell className="w-[25%] sticky top-0 bg-white">Name</TableCell>
                <TableCell className="w-[40%] sticky top-0 bg-white">Description</TableCell>
                <TableCell className="w-[20%] sticky top-0 bg-white">Uploaded Date</TableCell>
                <TableCell className="w-[5%] sticky top-0 bg-white">View</TableCell>
                <TableCell className="w-[5%] sticky top-0 bg-white">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>
                    <Tooltip title={row.name} placement="top">
                      <span className="truncate block max-w-[150px]">{row.name}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={row.description} placement="top">
                      <span className="truncate block max-w-[200px]">{row.description}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {new Date(row.uploaded_on)
                      .toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      variant="outlined"
                      size="small"
                      className="p-2"
                      onClick={() => setModalOpen({ type: "view", data: row.file_location })}
                    >
                      <FaEye size={16} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton variant="outlined" size="small" color="error" className="p-2">
                      <FaTrashAlt size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal */}
      <Modal
        open={!!isModalOpen}
        onClose={handleModalClose}
        className="flex items-center"
      >
        <Box
          className="bg-[#FAFAFA] mx-auto w-[400px] rounded-lg shadow-lg relative py-4 max-h-[90vh] overflow-y-auto"
        >
          {/* Close Icon */}
          <IconButton
            onClick={handleModalClose}
            className="absolute top-2 right-2"
            size="small"
            aria-label="close"
            disabled={disable}
          >
            <IoMdCloseCircleOutline size={18} />
          </IconButton>

          {/* Modal Content */}
          {renderModalContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default Index;
