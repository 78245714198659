import React from 'react'
import { FaMicrophone } from 'react-icons/fa'

const PulsatingButton = ({ type = 'primary', className = '' }) => {
  return (
    <button
      className={`relative w-20 h-20 rounded-full text-white cursor-pointer select-none ${
        type === 'primary' ? 'bg-[#F97171]' : 'bg-[#189BFF]'
      } ${className}`}
    >
      <div
        className={`absolute inset-0 rounded-full 
        ${type === 'primary' ? 'bg-[#F97171]' : 'bg-[#189BFF]'} 
        animate-ping`}
      ></div>
      <div
        className={`absolute inset-0 rounded-full 
        ${type === 'primary' ? 'bg-[#F97171]' : 'bg-[#189BFF]'} 
        animate-pulse`}
      ></div>
      <FaMicrophone className="relative z-10 text-4xl mx-auto" />
    </button>
  )
}

export default PulsatingButton