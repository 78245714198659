import Axios, { AxiosInstance } from 'axios';
import {
  social,
} from './configuration';

/**
 * Requests
 */
class Requests {
  static client: Requests;

  axios: AxiosInstance;

  constructor() {
    this.axios = Axios.create({
      // baseURL: process.env.REACT_APP_AUTH_DOMAIN,
      baseURL: 'https://app.aishaala.com/'
    });
    this.axios.defaults.timeout = 35000;
    this.axios.defaults.withCredentials = false;
  }

  static getInstance = () => {
    if (!Requests.client) {
      Requests.client = new Requests();
    }
    return Requests.client;
  };

  google = () => this.axios.get(social.google).then((res) => res.data);

  googleLoginDone = (code) => this.axios.get(social.googleLoginDone, { params: { code } })
    .then((res) => res.data)
}

export default Requests;