import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  FaCloud,
  FaGraduationCap,
  FaCommentDots,
  FaRobot,
  FaTimes,
  FaBars,
} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import {
  MdAccountCircle,
  MdOutlineLogout,
  MdMiscellaneousServices,
} from "react-icons/md";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { PiStudentBold } from "react-icons/pi";

import Logo from "../../assets/images/AishalaLogo.png";

const SideNavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Main Nav Links (excluding footer actions)
  const navLinks = [
    { path: "/voice", icon: <FaRobot />, tooltip: "Voice AI" },
    { path: "/chat", icon: <FaCommentDots />, tooltip: "Chat" },
    { path: "/knowledge", icon: <FaCloud />, tooltip: "Knowledge" },
    { path: "/courses", icon: <FaGraduationCap />, tooltip: "Courses" },
    { path: "/finetune", icon: <MdMiscellaneousServices />, tooltip: "Finetune" },
    { path: "/students", icon: <PiStudentBold />, tooltip: "Students" },
    { path: "/credit", icon: <RiMoneyRupeeCircleFill />, tooltip: "Credit" },
  ];

  // Footer Actions
  const footerLinks = [
    { path: "/account", icon: <MdAccountCircle />, tooltip: "Account" },
    { path: "/settings", icon: <IoMdSettings />, tooltip: "Settings" },
    { path: "/logout", icon: <MdOutlineLogout />, tooltip: "Logout" },
  ];

  return (
    <div className="relative">
      {/* Floating expand/contract button */}
      {/* <button
        onClick={toggleMenu}
        className={`fixed top-1 z-50 shadow-lg transition-all duration-300 px-0 rounded text-xs ${
          isOpen ? "left-[65px]" : "left-1"
        }`}
      >
        {isOpen ? <FaTimes className="text-slate-300" /> : <FaBars className="text-slate-300" />}
      </button> */}

      {/* SideNavBar */}
      <div
        className={`h-screen bg-slate-50 text-black transition-all duration-300 ${isOpen ? "w-16" : "w-0"
          } overflow-hidden`}
      >
        <div className="h-full flex flex-col">
          <div className="flex justify-center mt-1">
            <Tooltip title="Aishaala" placement="right">
              <Link to="/">
                <img
                  src={Logo}
                  className="w-12 mt-1"
                  alt="AISHAALA Logo"
                />
              </Link>
            </Tooltip>
          </div>

          <div className="flex-1 flex flex-col overflow-y-auto no-scrollbar">
            {/* Main Nav Links */}
            <div className="flex-1 mt-10">
              <div className="flex flex-col">
                {navLinks.map((link, index) => (
                  <Tooltip title={link.tooltip} key={index} placement="right">
                    <Link
                      to={link.path}
                      className={`flex items-center justify-center py-3 px-4 hover:bg-gray-200 text-black transition-all duration-300 min-h-[48px] w-full ${
                        location.pathname.startsWith(link.path)
                          ? "bg-[#F97171]/[0.1] text-[#F97171] border-r-2 border-[#F97171]"
                          : ""
                      }`}
                    >
                      <div>{React.cloneElement(link.icon, { className: "text-xl text-slate-500" })}</div>
                    </Link>
                  </Tooltip>
                ))}
              </div>
            </div>

            {/* Footer Links */}
            <div className="mt-auto">
              <div className="flex flex-col">
                {footerLinks.map((link, index) => (
                  <Tooltip title={link.tooltip} key={index} placement="right">
                    <Link
                      to={link.path}
                      className={`flex items-center justify-center py-3 px-4 hover:bg-gray-200 text-black transition-all duration-300 min-h-[48px] w-full ${
                        location.pathname.startsWith(link.path)
                          ? "bg-[#F97171]/[0.1] text-[#F97171] border-r-2 border-[#F97171]"
                          : ""
                      }`}
                    >
                      <div>{React.cloneElement(link.icon, { className: "text-xl text-slate-500" })}</div>
                    </Link>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
