/* eslint-disable import/prefer-default-export, no-else-return */
function getContentType(fileName, fileURL) {
  let extension;
  if (fileURL) {
    extension = fileURL.split('.').pop()?.toLowerCase();
  } else {
    extension = fileName.split('.').pop()?.toLowerCase();
  }
  if (extension === 'png') {
    return 'image/png';
  } else if (extension === 'jpg' || extension === 'jpeg') {
    return 'image/jpeg';
  } else if (extension === 'gif') {
    return 'image/gif';
  } else if (extension === 'pdf') {
    return 'application/pdf';
  } else if (extension === 'doc' || extension === 'docx') {
    return 'application/msword';
  } else if (extension === 'xls' || extension === 'xlsx') {
    return 'application/vnd.ms-excel';
  } else if (extension === 'ppt' || extension === 'pptx') {
    return 'application/vnd.ms-powerpoint';
  } else if (extension === 'csv') {
    return 'text/csv';
  } else if (extension === 'txt') {
    return 'text/plain';
  } else if (extension === 'html') {
    return 'text/html';
  } else if (extension === 'xml') {
    return 'application/xml';
  } else if (extension === 'json') {
    return 'application/json';
  } else if (extension === 'mp3') {
    return 'audio/mp3';
  } else if (extension === 'wav') {
    return 'audio/wav';
  } else if (extension === 'mp4') {
    return 'video/mp4';
  } else if (extension === 'avi') {
    return 'video/x-msvideo';
  }

  return undefined;
}

function getFileExtension(fileName) {
  const extension = fileName.split('.').pop()?.toLowerCase();
  return extension;
}

export {
  getContentType,
  getFileExtension,
};
