import React, { useState, useCallback } from 'react';
import { Button, CircularProgress } from '@mui/material';
import {
  FormProvider,
  useForm,
  Controller,
} from 'react-hook-form';
import * as yup from 'yup';
import { useDropzone } from "react-dropzone";

import RHFInput from '../../../../components/elements/Input/RHFInput';
import { confirm } from '../../../../components/Confirmable';
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver';

const FileDropzone = ({ name, control, label, rules, disable }) => {
  const onDrop = useCallback((acceptedFiles, onChange) => {
    if (acceptedFiles.length) {
      onChange(acceptedFiles[0]);
    }
  }, []);

  const DropzoneContent = ({ value, onChange, error }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, onChange),
      multiple: false,
      accept: {
        "application/pdf": [".pdf"],
        "text/plain": [".txt"],
        "application/msword": [".doc", ".docx"]
      },
      disabled: disable || !!value,  // Disable the dropzone if the disable prop is true or if a file is already selected
    });

    const handleRemoveFile = () => {
      onChange(null);
    };

    return (
      <div className="mb-4">
        <label className="block font-medium text-sm text-gray-700">{label}</label>
        {value ? (
          <div className="flex items-center justify-between border border-gray-400 p-4 rounded-md bg-gray-50">
            <p className="text-gray-600">{value.name}</p>
            <button
              type="button"
              onClick={handleRemoveFile}
              className="text-red-500 hover:text-red-700 text-sm font-medium"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={`border-dashed border-2 p-4 rounded-md bg-gray-50 text-center cursor-pointer ${error ? "border-red-500" : "border-gray-400"
              }`}
          >
            <input {...getInputProps()} />
            <p className="text-gray-600">
              Drag and drop a file here, or click to select one
            </p>
          </div>
        )}
        {error && <p className="text-red-600 text-sm mt-2">{error.message}</p>}
      </div>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DropzoneContent value={value} onChange={onChange} error={error} />
      )}
    />
  );
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Enter Valid Name')
    .required('Name is required'),
  category: yup
    .string()
    .typeError('Enter Valid Category')
    .required('Category is required'),
  file_content: yup.mixed()
    .required("File is required")
    // .test("fileSize", "File size must be less than 1MB", (value) => {
    //   return value && value.size <= 1 * 1024 * 1024;
    // })
    .test("fileType", "Unsupported file format", (value) => {
      return value && [
        "application/pdf",
        "text/plain",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ].includes(value.type);
    }),
});

function AddFile(props) {
  const {
    createNotes,
    updateNotes,
    defaultValues,
    disable,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    defaultValues: defaultValues || {
      name: '',
      category: '',
      file_content: null,
    },
    resolver,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { id } = defaultValues || {};
  const confirmationMessage = id
    ? 'Are you sure you want to Update?'
    : 'Are you sure you want to Create?';

  const submitHandler = async (data) => {
    if (await confirm({ confirmation: confirmationMessage, iconType: 'warning' })) {
      try {
        setIsSubmitting(true);
        await createNotes(data);
      } catch {
        console.log('Something went wrong ...');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex justify-center items-center w-full h-[60px] py-10">
          <div className="flex justify-center text-[#28364C] font-medium text-base">
            {id ? 'Update Notes' : 'Create Notes'}
          </div>
        </div>
        <div className="px-[10%] bg-[#FAFAFA] py-[4%] flex flex-col gap-5">
          <RHFInput
            name="name"
            control={methods.control}
            label="Name"
            disabled={disable || isSubmitting}
          />
          <RHFInput
            name="category"
            control={methods.control}
            label="Category"
            disabled={disable || isSubmitting}
          />
          <FileDropzone
            name="file_content"
            control={methods.control}
            label="Upload File"
            disable={disable || isSubmitting}
          />
        </div>

        <div className="flex justify-center items-center py-[30px] gap-4">
          <Button
            variant="outlined"
            size="large"
            disabled={!isDirty || disable || isSubmitting}
            onClick={() => reset(defaultValues)}
          >
            Reset
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={disable || isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? (id ? 'Updating...' : 'Creating...') : (id ? 'Update' : 'Create')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

AddFile.defaultProps = {
  defaultValues: {
    id: undefined,
    name: '',
    category: '',
    file_content: null,
  },
};

AddFile.propTypes = {};

export default AddFile;
