import { createTheme } from '@mui/material/styles';
import palette from './palette';
import components from './components';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      dialogSmall: 738,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: '"Lato", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette,
  components,
});

export default theme;
