import React from 'react';
import { useController } from 'react-hook-form';
import TextArea from '.';

function RHFTextArea({
  name,
  control,
  value,
  label,
  disabled,
  ...rest
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: value,
  });

  const { onChange } = field;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <TextArea
      label={label}
      value={field.value}
      disabled={disabled}
      error={error}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default RHFTextArea;
