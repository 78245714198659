const userinfo = {
    userinfo: 'userinfo',
}

const knowledge = {
    getAllKnowledge: 'knowledge',
    getKnowledge: 'knowledge',
    createKnowledge: 'knowledge',
    deleteKnowledge: 'knowledge',
    loadKnowledge: 'knowledge/load',
    chatSession: 'knowledge/get_chat_token',
}

const folder = {
    getAllFolder: 'folder',
    getFolder: 'folder',
    createFolder: 'folder',
    editFolder: 'folder',
    deleteFolder: 'folder',
}

const institute = {
    register: 'institute',
}

const index = {
    getAllIndex: 'index/files',
    createAndParseIndex: 'index',
    editIndex: '',
    deleteIndex: '',
}

export {
    knowledge,
    userinfo,
    folder,
    institute,
    index,
}
