import React, { useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';
import { MdOutlineTune } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";

import SideNavBar from '../../components/SideBar';

const FineTune = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabsList = [
    {
      icon: <MdOutlineTune size="25" />,
      value: 'finetune',
      displayText: 'Finetune',
      to: '/finetune/finetune',
    },
    {
      icon: <VscReferences size="25" />,
      value: 'index',
      displayText: 'Index',
      to: '/finetune/index',
    },
  ];

  // Extract the first part of the URL path after `/knowledge/`, ignoring extra path segments
  const currentTab = location.pathname.split('/')[2] || 'finetune';  // default to 'notes' if no tab

  useEffect(() => {
    // If the location is '/knowledge', navigate to '/knowledge/notes'
    if (location.pathname === '/finetune') {
      navigate('/finetune/finetune');
    }
  }, [location, navigate]);

  const handleTabChange = (event, newValue) => {
    navigate(`/finetune/${newValue}`);
  };

  return (
    <div className="flex">
      <SideNavBar />

      <div className="grow relative">
        <Box className="bg-white overflow-hidden">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabsList.map((tab) => (
              <Tab
                key={tab.value}
                label={(
                  <div className="flex items-center">
                    {tab.icon}
                    <span className="ml-2 capitalize font-bold">
                      {tab.displayText}
                    </span>
                  </div>
                )}
                value={tab.value}
                component={Link}
                to={tab.to}
                className={`
                  text-sm font-bold py-4 px-5 transition-all 
                  ${currentTab === tab.value ? 'text-[#F97171] bg-[#FBE9E7]' : 'text-gray-600'}
                  ${currentTab === tab.value ? 'font-bold' : ''}
                  hover:text-[#F97171] hover:bg-gray-100
                `}
              />
            ))}
          </Tabs>
        </Box>
        <Outlet />
      </div>
    </div>
  );
}

export default FineTune;
