import React, { useEffect } from 'react';
import { InputLabel, TextField } from '@mui/material';
import ElementUtils from '../utils';

function Textarea({
  label,
  value,
  disabled,
  rows,
  error,
  onChange,
  placeholder,
  maxCharacterLimit,
  handleCharacterCount,
  characterCount,
}) {
  const handleValueChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  useEffect(() => {
    if (maxCharacterLimit && handleCharacterCount && value !== undefined) {
      handleCharacterCount(value.length);
    }
  }, [value, maxCharacterLimit, handleCharacterCount]);

  return (
    <div className="flex flex-col relative justify-center items-start">
      {label && (
        <InputLabel
          className={`font-medium text-[#28364C] text-sm leading-[23px] ${
            error ? 'text-red-500' : 'text-[#28364C]'
          }`}
          error={!!error}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        placeholder={placeholder || label}
        className="w-full mt-1"
        multiline
        rows={rows}
        value={value}
        disabled={disabled}
        maxLength={maxCharacterLimit}
        onChange={handleValueChange}
        variant="outlined"
        error={!!error}
        helperText={characterCount ? `${characterCount}/${maxCharacterLimit} ` : ''}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderColor: error ? 'red' : '#d1d5db', // Tailwind border-gray-300
            '&:hover': {
              borderColor: error ? 'red' : '#9ca3af', // Tailwind border-gray-400 on hover
            },
            '&.Mui-focused': {
              borderColor: error ? 'red' : '#3b82f6', // Tailwind border-blue-500 on focus
            },
            resize: 'vertical', // Makes the textarea vertically resizable
          },
          '& .MuiInputBase-input': {
            fontWeight: '500',
            padding: '10px',
          },
        }}
      />
      {error && (
        <div className="absolute -bottom-5 text-red-500 text-xs">
          {ElementUtils.renderError(error)}
        </div>
      )}
    </div>
  );
}

Textarea.defaultProps = {
  label: '',
  value: '',
  rows: 4,
  disabled: false,
  onChange: () => {},
  maxCharacterLimit: undefined,
  handleCharacterCount: undefined,
  characterCount: undefined,
};

export default Textarea;
