/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { useController } from 'react-hook-form';
import Input from '.';

function RHFInput(props) {
  const {
    name,
    control,
    value,
    label,
    helperText,
    disabled,
    onInputChange,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: value || undefined,
  });

  const { onChange } = field;

  const handleChange = (event) => {
    onChange(event);
    if (onInputChange) {
      onInputChange(event);
    }
  };

  return (
    <Input
      label={label}
      value={field.value}
      helperText={helperText}
      disabled={disabled}
      onChange={handleChange}
      error={error}
      {...rest}
    />
  );
}

export default RHFInput;
