import Axios, { AxiosInstance } from 'axios';
import toast from 'react-hot-toast';
import {
  userinfo,
  folder,
  knowledge,
  institute,
  index,
} from './configuration';

/**
 * Requests
 */
class Requests {
  static client: Requests;

  axios: AxiosInstance;;

  constructor() {
    this.axios = Axios.create({
      // baseURL: process.env.REACT_APP_ROOT_DOMAIN,
      baseURL: 'https://app.aishaala.com/api/v1/',
    });
    this.axios.defaults.withCredentials = false;
    this.axios.defaults.timeout = 35000;

    this.axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('app_token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;
    
        if (status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else if (status === 500) {
          toast.error('Server error. Please try again later.');
        } else if (status) {
          toast.error('Something went wrong');
        } else {
          // Network error or other issues
          toast.error('Network error. Please check your connection.');
        }
    
        // Propagate the error so it can be handled by individual callers if needed
        return Promise.reject(error);
      }
    );
  }

  setHeader = (instituteId) => {
    this.axios.defaults.headers.common['X-Institute-Id'] = instituteId;
  }

  static getInstance = () => {
    if (!Requests.client) {
      Requests.client = new Requests();
    }
    return Requests.client;
  };

  userinfo = () => this.axios.get(userinfo.userinfo).then((res) => res.data);

  getAllFolder = () => this.axios.get(folder.getAllFolder).then((res) => res.data);

  getFolder = (folder_id) => this.axios.get(`${folder.getFolder}/${folder_id}`).then((res) => res.data);

  createFolder = (body) => this.axios.post(folder.createFolder, body).then((res) => res.data);

  editFolder = (folder_id) => this.axios.put(`${folder.editFolder}/${folder_id}`).then((res) => res.data);

  deleteFolder = (folder_id) => this.axios.delete(`${folder.deleteFolder}/${folder_id}`).then((res) => res.data);

  getAllKnowledge = (folderId) => this.axios.get(knowledge.getAllKnowledge, {
    params: {
      folder_id: folderId
    }
  }).then((res) => res.data);

  getKnowledge = (knowledge_id) => this.axios.get(`${knowledge.getKnowledge}/${knowledge_id}`).then((res) => res.data);

  createKnowledge = (formData) => this.axios.post(knowledge.createKnowledge, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...this.axios.defaults.headers.common,
    }
  }).then(response => response.data)

  buildKnowledge = (knowledge_id) => this.axios.post(`${knowledge.loadKnowledge}/${knowledge_id}`);

  // editKnowledge = (knowledge_id) => this.axios.put(`${knowledge.editKnowledge}/${knowledge_id}`).then((res) => res.data);

  deleteKnowledge = (knowledge_id) => this.axios.delete(`${knowledge.deleteKnowledge}/${knowledge_id}`).then((res) => res.data);

  getChatSessionId = () => this.axios.get(knowledge.chatSession).then((res) => res.data);

  registerInstitute = (formData) => this.axios.post(institute.register, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...this.axios.defaults.headers.common,
    }
  }).then(response => response.data)

  getAllIndex = () => this.axios.get(index.getAllIndex).then((res) => res.data);

  createAndParseIndex = (formData) => this.axios.post(index.createAndParseIndex, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...this.axios.defaults.headers.common,
    }
  }).then(response => response.data)
}

export default Requests;